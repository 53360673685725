import React from 'react';
import Headings from "../Headings";

/**
 * Paste in your SVG logo and return it from this component.
 * Make sure you have a height set for your logo.
 * It is recommended to keep the height within 25-35px.
 */
export default function Logo() {
  return (
      <Headings.h1 style={{
        // fontFamily : 'Myriad bold, Noto Sans light, Roboto light',
        fontSize : '1.6em',
        letterSpacing: '-0.4px'}}>
        Slowalk Datalab
      </Headings.h1>
  );
}
